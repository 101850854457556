import cn from "classnames";
import Link from "next/link";
import { Button, Stack } from "react-bootstrap";

import classes from "./styles.module.scss";

export default function ({
  className = "justify-content-center justify-content-md-start",
  buttons = [],
}) {
  if (buttons.length === 0) return null;

  return (
    <Stack className={cn(className, "", classes.Buttons)}>
      {buttons.map(({ url, variant, active, label, icon, onclick }, idx) => (
        <Button
          as={Link}
          key={idx}
          href={url ?? "#"}
          active={active}
          target={url && url.startsWith("http") ? "_blank" : null}
          variant={variant}
          onClick={onclick}
        >
          <span>{label}</span>
          {icon ? <i>{icon}</i> : null}
        </Button>
      ))}
    </Stack>
  );
}
